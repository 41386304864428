.films-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: white;
  max-width: 75%;
  /* Set maximum width to 80% of the screen */
  margin: 0 auto;
  /* Center the films container horizontally */
}

.film-item {
  flex-basis: calc(33.33% - 10px);
  margin: 5px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.film-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.film-item h3 {
  margin-top: 10px;
  font-size: 16px;
}

.film-item p {
  margin: 0;
  flex-grow: 1;
}

@media (max-width: 1024px) {
  .film-item {
    flex-basis: calc(50% - 10px);
    /* Update flex-basis for 2 films per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .film-item {
    flex-basis: 100%;
    /* Update flex-basis for 1 film per row on smaller screens */
  }
}

/* Light Theme */
.light-theme {
  background-color: #ffffff;
  color: #000000;
}

.light-theme .films-container {
  background-color: transparent;
}

.light-theme .film-item {
  color: #000000;
}

/* Dark Theme */
.dark-theme {
  background-color: #242121;
}

.dark-theme .films-container {
  background-color: #242121;
  color: #000000;
  /* Add the color property to ensure text is visible */
}

.film-item button {
  padding: 8px 16px;
  background-color: #333333;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.film-item button:hover {
  background-color: #555555;
}

/* Styles for the modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  max-width: 400px;
  background-color: #fff;
  padding: 20px;
}

.modal h3 {
  margin-top: 0;
}

.modal p {
  margin-bottom: 10px;
}

.modal button {
  margin-top: 10px;
}

/* Additional styles for the film item */
.film-item {
  position: relative;
}

.film-item h3 {
  margin: 10px 0;
}

.film-item p {
  margin: 5px 0;
}

.film-item button {
  margin-top: 10px;
}

.modal img {
  max-width: 45%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  object-fit: cover;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: auto;
}

.modal-content {
  width: 80%;
  height: 555px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
  display: inline;
}

body {
  margin: 0;
}


/* Contact.js */
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

label {
  margin-bottom: 5px;
}

input,
textarea {
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button[type='submit'] {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #0056b3;
}

/* Header.css */

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f2f2f2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav ul li {
  font-size: 18px;
}

.dark-mode-switch {
  display: flex;
  align-items: center;
}

.dark-mode-switch label {
  margin-right: 10px;
}